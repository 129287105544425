// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require("@rails/ujs")
Rails.start();
global.Rails = Rails;
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("../lib");
require("../styles/index.scss");