import clickOutside from "./clickOutside";
import autocomplete from "./autocomplete";

const handleDialogs = () => {
  const dialogOpeners = document.querySelectorAll("[data-dialog]");
  [...dialogOpeners].forEach((dialogOpener) => {
    dialogOpener.addEventListener("click", (e) => {
      e.preventDefault();
      const targetId = dialogOpener.getAttribute("data-dialog");
      const target = document.querySelector(`#${targetId}`);
      clickOutside(
        target.querySelector(".card"),
        () => {
          document.querySelector("body").style.overflow = "hidden";
          target.style.display = "flex";
          autocomplete();
        },
        () => {
          target.style.display = "none";
          document.querySelector("body").style.overflow = "auto";
        }
      );
    });
  });
};

document.addEventListener("turbolinks:load", handleDialogs);
export default handleDialogs;
