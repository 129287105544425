import submitToken from "./submitToken";
import style from "./styles";

const setOutcome = (result) => {
	const successElement = document.querySelector(".success");
	const errorElement = document.querySelector(".error");
	successElement.classList.remove("visible");
	errorElement.classList.remove("visible");

	if (result.token) {
		submitToken(result.token.id);
	} else if (result.error) {
		errorElement.textContent = result.error.message;
		errorElement.classList.add("visible");
	}
};

export default (stripe, stripeNode, elements) => {
	const cardNumberElement = elements.create("cardNumber", {
		style,
		placeholder: "Enter card number here...",
	});
	cardNumberElement.mount("#card-number-element");

	const cardExpiryElement = elements.create("cardExpiry", {
		style,
		placeholder: "Enter expiry date (MM/YY)",
	});
	cardExpiryElement.mount("#card-expiry-element");

	const cardCvcElement = elements.create("cardCvc", {
		style,
		placeholder: "Enter verification code (CVC)",
	});
	cardCvcElement.mount("#card-cvc-element");

	cardNumberElement.on("change", (event) => {
		setOutcome(event);
	});

	stripeNode.addEventListener("submit", (e) => {
		e.preventDefault();

		const options = {
			address_zip: document.getElementById("postal-code").value,
		};

		stripe.createToken(cardNumberElement, options).then(setOutcome);
	});
};
