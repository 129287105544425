import Rails from "@rails/ujs";

const suggest = (element) => {
  element.addEventListener("keyup", (e) => {
    e.preventDefault();

    if (
      e.key === "Enter" ||
      event.code === "Enter" ||
      event.keyCode === 13 ||
      event.which === 13 ||
      event.keyCode === "13" ||
      event.which === "13"
    ) {
      const currentLocation = window.location.pathname;
      const { value } = e.target;
      Turbolinks.visit(`${currentLocation}?q=${value}`);
    }
  });
};

const autocompleteProducts = () => {
  const boxes = document.querySelectorAll(".autocompleteProducts");
  [...boxes].forEach(suggest);
};

document.addEventListener("turbolinks:load", autocompleteProducts);
export default autocompleteProducts;
