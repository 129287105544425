import Sortable from "sortablejs";
import railsRequest from "./railsRequest";

const enableSaleLinks = () => {
  const results = document.querySelectorAll(".result");

  [...results].forEach((link) => {
  	const url = link.getAttribute('href');
  	const method = link.getAttribute('data-method');

  	link.addEventListener('click', e => {
  		e.preventDefault();
  		e.stopPropagation();

      Rails.ajax({
        dataType: 'script',
        url,
        type: method,
        beforeSend: function() {
          return true
        },
        success: function(data) {
          console.log('Carregou')
        }
      })
  		
  	})
  });
};

document.addEventListener("turbolinks:load", enableSaleLinks);
window.enableSaleLinks = enableSaleLinks;
