import axios from "axios";

const headerToken = () =>
  document.querySelector('[name="csrf-token"]').getAttribute("content");

const railsRequest = ({ method, url, data = {}, cb, headers = {} }) => {
  axios({
    method,
    url,
    data,
    headers: {
      ...headers,
      "X-CSRF-Token": headerToken(),
    },
  }).then(cb);
};

export default railsRequest;
