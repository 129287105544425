const addQueryParam = (url, key, val) => {
  var parts = url.match(/([^?#]+)(\?[^#]*)?(\#.*)?/);
  var url = parts[1];
  var qs = parts[2] || "";
  var hash = parts[3] || "";

  if (!qs) {
    return url + "?" + key + "=" + encodeURIComponent(val) + hash;
  } else {
    var qs_parts = qs.substr(1).split("&");
    var i;
    for (i = 0; i < qs_parts.length; i++) {
      var qs_pair = qs_parts[i].split("=");
      if (qs_pair[0] == key) {
        qs_parts[i] = key + "=" + encodeURIComponent(val);
        break;
      }
    }
    if (i == qs_parts.length) {
      qs_parts.push(key + "=" + encodeURIComponent(val));
    }
    return url + "?" + qs_parts.join("&") + hash;
  }
};

const removeQueryParam = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
};

export { addQueryParam, removeQueryParam };
