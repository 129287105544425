import axios from "axios";

export default (token_id) => {
	const railsToken = document
		.querySelector('meta[name="csrf-token"]')
		.getAttribute("content");

	axios
		.put(
			"/shop/sale/payment_method",
			{ token_id },
			{
				headers: {
					"X-CSRF-Token": railsToken,
				},
			}
		)
		.then((response) => {
			window.location.href = "/shop/payment_method";
		});
};
