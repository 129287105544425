import clickOutside from "./clickOutside";
import handleDialogs from "./dialog";

export const handleDropdowns = () => {
  const dropdownsOpeners = document.querySelectorAll("[data-dropdown-target]");
  [...dropdownsOpeners].forEach((dropdownOpener) => {
    dropdownOpener.addEventListener("click", (e) => {
      e.preventDefault();
      const targetId = dropdownOpener.getAttribute("data-dropdown-target");
      const target = document.querySelector(`#${targetId}`);
      clickOutside(
        target,
        () => (target.style.display = "block"),
        () => (target.style.display = "none")
      );
    });
  });
};

window.handleDropdowns = handleDropdowns;
document.addEventListener("turbolinks:load", handleDropdowns);
