import Sortable from "sortablejs";
import railsRequest from "./railsRequest";

const debouncedSortables = () => {
  const sortables = document.querySelectorAll("[data-debounced-sortable-url]");

  [...sortables].forEach((sortableWrapper) => {
    const card = sortableWrapper.closest(".card");
    if (card) {
      const footer = card.querySelector(".cardFooter");
      const footerButton = footer.querySelector("button");

      if (footerButton) {
        let ids, timeout;

        footerButton.addEventListener("click", (e) => {
          e.preventDefault();
          footerButton.classList.add("opacity-50");
          footerButton.innerText = "Saving...";
          footerButton.style.pointerEvents = "none";

          railsRequest({
            method: "put",
            url: sortableWrapper.getAttribute("data-debounced-sortable-url"),
            data: { ids },
            cb: () => {
              footerButton.innerText = "Changes saved!";
              timeout = setTimeout(() => {
                footerButton.classList.remove("opacity-50");
                footerButton.innerText = "Save changes";
                footerButton.style.pointerEvents = "auto";
                footer.style.display = "none";
              }, 1500);
            },
          });
        });

        var sortable = new Sortable(sortableWrapper, {
          onEnd: (evt) => {
            const children = sortableWrapper.querySelectorAll("li");
            ids = [...children].map((child) =>
              child.getAttribute("data-sortable-id")
            );

            clearTimeout(timeout);
            footer.style.display = "flex";
          },
        });
      }
    }
  });
};

document.addEventListener("turbolinks:load", debouncedSortables);
