import Sortable from "sortablejs";
import railsRequest from "./railsRequest";

const enableSortables = () => {
  const sortables = document.querySelectorAll("[data-sortable-url]");

  [...sortables].forEach((sortableWrapper) => {
    var sortable = new Sortable(sortableWrapper, {
      onEnd: (evt) => {
        const children = sortableWrapper.querySelectorAll("li");
        const ids = [...children].map((child) =>
          child.getAttribute("data-sortable-id")
        );

        railsRequest({
          method: "put",
          url: sortableWrapper.getAttribute("data-sortable-url"),
          data: { ids },
        });
      },
    });
  });
};

document.addEventListener("turbolinks:load", enableSortables);
