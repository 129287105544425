import { addQueryParam } from "./addQueryParam";

class CheckboxesActions {
  constructor(selectAllCheckbox) {
    this.selectAllCheckbox = selectAllCheckbox;
    this.context = selectAllCheckbox.closest(".card");
    this.listHeader = this.context.querySelector(".listHeader");
    this.checkboxesActionIds = [];

    this.selectAllChecked = false;

    this.lineItemCheckboxes = this.context.querySelectorAll(
      ".listItem .form-checkbox"
    );

    [...this.lineItemCheckboxes].forEach((lineItemCheckbox) => {
      lineItemCheckbox.addEventListener("click", () =>
        this.toggleOne(lineItemCheckbox)
      );
    });

    selectAllCheckbox.addEventListener("click", () => {
      this.toggleAll(selectAllCheckbox.checked);
    });
  }

  toggleOne(checkbox) {
    checkbox.checked ? this.addId(checkbox) : this.removeId(checkbox);
    this.updateLinks();

    const allChecked =
      this.checkboxesActionIds.length === this.lineItemCheckboxes.length;
    this.selectAllCheckbox.checked = allChecked;
  }

  toggleAll(selected) {
    [...this.lineItemCheckboxes].forEach((lineItemCheckbox) => {
      lineItemCheckbox.checked = selected;
      selected ? this.addId(lineItemCheckbox) : this.removeId(lineItemCheckbox);
      return;
    });
  }

  toggleActions(show) {
    show
      ? this.listHeader.classList.add("showingActions")
      : this.listHeader.classList.remove("showingActions");
  }

  addId(checkbox) {
    const id = checkbox.getAttribute("data-checkboxes-actions");
    this.checkboxesActionIds = [...this.checkboxesActionIds, id];
    this.updateLinks();
  }

  removeId(checkbox) {
    const id = checkbox.getAttribute("data-checkboxes-actions");
    this.checkboxesActionIds = this.checkboxesActionIds
      .filter((selectedId) => id !== selectedId)
      .map((id) => id);
    this.updateLinks();
  }

  updateLinks() {
    const actionsButtons = this.context.querySelectorAll(
      ".checkboxesActionsItem"
    );

    const hasSelectedIds = this.checkboxesActionIds.length >= 1;

    [...actionsButtons].forEach((button) => {
      const initialHref = button.getAttribute("data-initial-href");

      if (hasSelectedIds) {
        const ids = this.checkboxesActionIds.join(",");
        const href = addQueryParam(initialHref, "ids", ids);
        button.setAttribute("href", href);
      } else {
        button.setAttribute("href", initialHref);
      }
    });

    this.toggleActions(hasSelectedIds);
  }
}

const selectAllHandlers = () => {
  const selectAllButtons = document.querySelectorAll(".selectAllCheckboxes");
  [...selectAllButtons].forEach((checkbox) => {
    new CheckboxesActions(checkbox);
  });
};

window.selectAllHandlers = selectAllHandlers;

document.addEventListener("turbolinks:load", () => {
  selectAllHandlers();
  return;
});
