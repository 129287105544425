import { loadStripe } from "@stripe/stripe-js";
import cardSetup from "./card";
import sepaSetup from "./sepa";

const enableStripe = async () => {
	const stripeNode = document.querySelector("[data-stripe-publishable-key]");

	if (stripeNode) {
		const stripePublishableKey = stripeNode.getAttribute(
			"data-stripe-publishable-key"
		);
		const paymentType = stripeNode.getAttribute("data-payment-type");

		const stripe = await loadStripe(stripePublishableKey);
		const elements = stripe.elements();

		if (paymentType === "card") {
			cardSetup(stripe, stripeNode, elements);
		} else if (paymentType === "sepa") {
			sepaSetup(stripe, stripeNode, elements);
		}
	}
};

document.addEventListener("turbolinks:load", enableStripe);
