const isVisible = elem =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

const handleClickOutside = (element, onShow, onHide) => {
  const outsideClickListener = event => {
    if (!element.contains(event.target) && isVisible(element)) {
      onHide();
      removeClickListener();
    } else {
      onShow();
    }
  };

  const clickEscape = e => {
    if (e.keyCode == 27) {
      onHide();
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
    document.removeEventListener("keyup", clickEscape);
  };

  document.addEventListener("click", outsideClickListener);
  document.addEventListener("keyup", clickEscape);
};

export default handleClickOutside;
