import style from "./styles";
import submitToken from "./submitToken";

export default (stripe, stripeNode, elements) => {
  const iban = elements.create("iban", { style, supportedCountries: ["SEPA"] });

  iban.mount("#iban-element");

  const errorMessage = document.getElementById("error-message");
  const bankName = document.getElementById("bank-name");

  iban.on("change", (event) => {
    if (event.error) {
      errorMessage.textContent = event.error.message;
      errorMessage.classList.add("visible");
    } else {
      errorMessage.classList.remove("visible");
    }

    if (event.bankName) {
      bankName.textContent = event.bankName;
      bankName.classList.add("visible");
    } else {
      bankName.classList.remove("visible");
    }
  });

  stripeNode.addEventListener("submit", (event) => {
    event.preventDefault();

    const name = document.querySelector('input[name="name"]').value;
    const email = document.querySelector('input[name="email"]').value;

    const sourceData = {
      type: "sepa_debit",
      currency: "eur",
      owner: { name, email },
      mandate: { notification_method: "email" },
    };

    stripe.createSource(iban, sourceData).then((result) => {
      if (result.error) {
        errorMessage.textContent = result.error.message;
        errorMessage.classList.add("visible");
      } else {
        errorMessage.classList.remove("visible");
        submitToken(result.source.id);
      }
    });
  });
};
