import { addQueryParam, removeQueryParam } from "./addQueryParam";

const enablePerPageChange = () => {
  const changePerPages = document.querySelectorAll(".changePerPage");
  [...changePerPages].forEach((select) => {
    select.addEventListener("change", (e) => {
      const withPerPageParam = addQueryParam(
        window.location.href,
        "per_page",
        e.target.value
      );

      const finalUrl = removeQueryParam(withPerPageParam, "page");

      window.location.href = finalUrl;
    });
  });
};

document.addEventListener("turbolinks:load", enablePerPageChange);
