import Rails from "@rails/ujs";
import railsRequest from "./railsRequest";

let timeout;

const emptySuggestions = (target) => {
  target.style.display = "none";
  target.innerHTML = "";
};

const suggest = (element) => {
  const input = element;
  const form = element.closest("form");
  const target = element.querySelector(".target");

  if (form) {
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      return false;
    });
  }

  input.addEventListener("keyup", (e) => {
    const value = e.target.value;
    clearTimeout(timeout);

    if (value === "") {
      emptySuggestions(target);
    } else {
      timeout = setTimeout(() => {
        Rails.fire(form, "submit");
      }, 500);
    }
  });
};

const autocomplete = () => {
  const suggestsBoxes = document.querySelectorAll(".autocomplete");
  [...suggestsBoxes].forEach(suggest);
};

document.addEventListener("turbolinks:load", autocomplete);
export default autocomplete;
